import React from 'react'

const Technologies = (props) => {
    const { headingone, headingtwo, text1, text2, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13 } = props
    return (
        <div className='technologies_main_din'>
            <div className="technologies_heading_div">
                <h3>{headingone}  <b>{headingtwo}</b></h3>
                <p><span className='provide'>{text1}</span> {text2}</p>
            </div>
            <div className="images_div" data-aos="fade-right" data-aos-duration='1000' data-aos-delay='100'>
                <div className='img_div'><img className={img1 ? 'width120' : 'width70'} src={img1 || img8} alt="" /></div>
                <div className='img_div'><img className={img2 ? 'width120' : 'width125'} src={img2 || img9} alt="" /></div>
                <div className='img_div'><img className={img3 ? 'width150' : 'width130'} src={img3 || img10} alt="" /></div>
                <div className='img_div'><img className={img4 ? 'width135' : 'width120'} src={img4 || img11} alt="" /></div>
                <div className='img_div'><img className={img5 ? 'width150' : 'width180'} src={img5 || img12} alt="" /></div>
                <div className='img_div'><img className='width120' src={img6 || img13} alt="" /></div>
                {img7 ? <div className='img_div'><img className='width130' src={img7} alt="" /></div> : null}
            </div>
        </div>
    )
}

export default Technologies
