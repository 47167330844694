import { cloud_obj } from "../../utils/Constant"

const Cloud = () => {
    return (
        <>
            <div className="technologies_main_div">
                <h2 className="technologies_h2">
                    Cloud
                </h2>
                <p className="technologies_p">
                    Grow your business dynamically without burning dollars. Savor the benefit of
                    scalability, flexibility and affordability with the power of cloud.
                </p>
                <div className="technologies_card_main_div">
                    {cloud_obj?.map((v, i) => {
                        return (
                            <>
                                <div className="technologies_card_inner_div">
                                    <img src={v?.img} />
                                </div>
                            </>
                        )
                    })
                    }
                </div>
            </div>
        </>
    )
}
export default Cloud