import areeb_pic from '../assets/Image/areeb_pic.jpg'
import mansoor_pic from '../assets/Image/mansoor_pic.jpg'
import zain_pic from '../assets/Image/zain_pic.jpg'
import photoshop from '../assets/Image/photoshop.png'
import alex_pic from '../assets/Image/alex.jpg'
import sketch from '../assets/Image/sketch.png'
import adobe_after from '../assets/Image/adobe_after.png'
import invision from '../assets/Image/invision.png'
import mongodb from '../assets/Image/mongodb.png'
import next_js from '../assets/Image/next_js.png'
import node_js from '../assets/Image/node_js.png'
import typescript from '../assets/Image/typescript.png'
import figma from '../assets/Image/figma.png'
import CSS from '../assets/Image/CSS.png'
import angular_js from '../assets/Image/angular_js.png'
import HTML from '../assets/Image/HTML.png'
import BigQuery from '../assets/Image/BigQuery.png'
import baseCamp from '../assets/Image/baseCamp.png'
import trello from '../assets/Image/trello.png'
import mySQL from '../assets/Image/mySql.png'
import Java from '../assets/Image/Java.png'
import flutter from '../assets/Image/flutter.png'
import react from '../assets/Image/react.png'
import python from '../assets/Image/python.png'
import amazon from '../assets/Image/amazon.png'
import iCloud from '../assets/Image/iCloud.png'
import GCP from '../assets/Image/GCP.png'
import firebase from '../assets/Image/firebase.png'
import bitbucket from '../assets/Image/bitbucket.png'
import bootstrap from '../assets/Image/bootstrap.png'
import Jquery from '../assets/Image/Jquery.png'
import adobe_cc from '../assets/Image/adobe_cc.png'
import justinmind from '../assets/Image/justinmind.png'
import PostgresSQL from '../assets/Image/PostgresSQL.png'
import slack from '../assets/Image/slack.png'
import skype from '../assets/Image/skype.png'
import JS from '../assets/Image/JS.png'
import hangout from '../assets/Image/hangout.png'
import adobe from '../assets/Image/adobe.png'
import zeeshan_pic from '../assets/Image/zeeshan_pic.jpg'
import rehan_pic from '../assets/Image/rehan_pic.jpg'
import abbas_pic from '../assets/Image/abbas_pic.jpg'
import umair_pic from '../assets/Image/umair_pic.jpg'
import shariq_pic from '../assets/Image/shariq_pic.jpg'
import hafsa_pic from '../assets/Image/hafsa_pic.jpeg'
import yasir_pic from '../assets/Image/yasir_pic.jpg'
import Partner_img1 from '../assets/Image/Partner_img1.webp'
import Partner_img2 from '../assets/Image/Partner_img2.webp'
import Partner_img3 from '../assets/Image/Partner_img3.webp'
import Partner_img4 from '../assets/Image/Partner_img4.webp'
import Partner_img5 from '../assets/Image/Partner_img5.webp'
import Partner_img6 from '../assets/Image/Partner_img6.webp'
import Partner_img7 from '../assets/Image/Partner_img7.webp'
import Partner_img8 from '../assets/Image/Partner_img8.png'
import Partner_img9 from '../assets/Image/Partner_img9.webp'
import Partner_img10 from '../assets/Image/Partner_img10.webp'
import { BiImage } from "react-icons/bi";

let team_obj = [
    {
        img: mansoor_pic,
        name: 'Mansoor Hussain',
        pos: 'Founder',
        linkedin: 'https://www.linkedin.com/in/mansoor-hussain-28b97815b',
        email: 'hussainmansoor876@gmail.com'
    },
    {
        img: alex_pic,
        name: 'Alex',
        pos: 'CTO',
        // linkedin: 'https://www.linkedin.com/in/mansoor-hussain-28b97815b',
        email: 'alexbelotsky@gmail.com'
    },
    {
        img: yasir_pic,
        name: 'Muhammad Yasir',
        pos: 'Full Stack Developer',
        linkedin: 'https://www.linkedin.com/in/myasirkhan575',
        email: 'myasirkhan575@gmail.com'
    },
    {
        img: areeb_pic,
        name: 'Muhammad Areeb',
        pos: 'Full Stack Developer',
        linkedin: 'https://www.linkedin.com/in/areeb-dev',
        email: 'm.areebkhan125@gmail.com'
    },
    {
        img: abbas_pic,
        name: 'Mohammad Abbas',
        pos: 'Full Stack Developer',
        linkedin: 'https://www.linkedin.com/in/mohammed-abbas-303761200',
        email: 'm.abbas345.ma@gmail.com'
    },
    {
        img: zeeshan_pic,
        name: 'Muhammad Zeeshan',
        pos: 'Mobile App Developer',
        linkedin: 'https://www.linkedin.com/in/muhammad-zeeshan-66579a234',
        email: 'muhamadzeeshanahmed@gmail.com'
    },
    {
        img: shariq_pic,
        name: 'Shariq Mehmood',
        pos: 'Mobile App Developer',
        linkedin: 'https://www.linkedin.com/in/shariq-mehmood-3a51b5207',
        email: 'shariqmehmoodinfo@gmail.com'
    },
    {
        img: zain_pic,
        name: 'Zain Ali',
        pos: 'Frontend Developer',
        linkedin: 'https://www.linkedin.com/mwlite/in/zain-ali-57014027a',
        email: 'zainalisidd786@gmail.com'
    },
    {
        img: rehan_pic,
        name: 'Rehan Pasha',
        pos: 'Frontend Developer',
        linkedin: 'https://www.linkedin.com/in/rehan-pasha-778a9a23a',
        email: 'pasharehan732@gmail.com'
    },
    {
        img: umair_pic,
        name: 'Umair Rajput',
        pos: 'Frontend Developer',
        linkedin: 'https://www.linkedin.com/in/umair-hussain-03120127a',
        email: 'manzoorumair11@gmail.com'
    },
    {
        img: hafsa_pic,
        name: 'Hafsa Sohail',
        pos: 'Frontend Developer',
        linkedin: 'https://www.linkedin.com/in/hafsa-sohail-98b569233',
        email: 'hafsasohail3420@gmail.com'
    }
]

let partner_obj = [
    {
        img: Partner_img1,
        width: '120px'
    },
    {
        img: Partner_img2,
        width: '140px'
    },
    {
        img: Partner_img3,
        width: '120px'
    },
    {
        img: Partner_img4,
        width: '140px'
    },
    {
        img: Partner_img5,
        width: '110px'
    },
    {
        img: Partner_img6,
        width: '190px'
    },
    {
        img: Partner_img7,
        width: '140px'
    },
    {
        img: Partner_img8,
        width: '130px'
    },
    {
        img: Partner_img9,
        width: '130px'
    },
    {
        img: Partner_img10,
        width: '60px'
    },
]

let design_obj = [
    {
        img: photoshop
    },
    {
        img: adobe
    },
    {
        img: adobe_after
    },
    {
        img: sketch
    },
    {
        img: invision
    },
    {
        img: justinmind
    },
    {
        img: figma
    },
    {
        img: adobe_cc
    },
]

let database_obj = [
    {
        img: PostgresSQL
    },
    {
        img: mongodb
    },
    {
        img: firebase
    },
    {
        img: BigQuery
    },
    {
        img: mySQL
    },
]

let cloud_obj = [
    {
        img: amazon
    },
    {
        img: GCP
    },
    {
        img: iCloud
    },
]

let app_tech_obj = [
    {
        img: react
    },
    {
        img: flutter
    },
    {
        img: Java
    }
]

let web_tech_obj = [
    {
        img: JS,
        width: '105px'
    },
    {
        img: HTML
    },
    {
        img: CSS
    },
    {
        img: Jquery
    },
    {
        img: python
    },
    {
        img: react
    },
    {
        img: typescript
    },
    {
        img: next_js
    },
    {
        img: node_js
    },
    {
        img: angular_js
    },
    {
        img: bootstrap
    },
]

let strategies_obj = [
    {
        img: baseCamp
    },
    {
        img: trello
    },
    {
        img: slack
    },
    {
        img: bitbucket
    },
    {
        img: skype
    },
    {
        img: hangout
    },
]

let projects = [
    {
        name: "Keep In Touch",
        url: "https://2keepintouch.com/"
    },
    {
        name: "Provincie Deals",
        url: "https://provinciedeals.nl/"
    },
    {
        name: "Testally",
        url: "https://testally.com/"
    },
    {
        name: "Value Market",
        url: "http://vmvm.co.kr/"
    },
    {
        name: "Taytay Tunes",
        url: "https://www.taytaytunes.com/"
    },
    {
        name: "Machpdf",
        url: "https://www.machpdf.com/"
    },
    {
        name: "Context",
        url: "https://context-2my7afm7yq-ue.a.run.app/"
    },
    {
        name: "247 IT International",
        url: "https://247itinternational.com/"
    },
    {
        name: "Cash Transfer App",
        url: "https://cash-transfer.herokuapp.com/"
    },
    {
        name: "Wood Square Realty",
        url: "https://wsr-hermes-frontend.herokuapp.com/"
    },
    {
        name: "Smart Profile",
        url: "https://smartprofile876.herokuapp.com/"
    },
    {
        name: "Tentstat",
        url: "https://tenstat.com"
    }
]
export {
    team_obj,
    partner_obj,
    design_obj,
    database_obj,
    cloud_obj,
    app_tech_obj,
    web_tech_obj,
    strategies_obj,
    projects
}