import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { Navbar, Footer, ScrollBar } from '../Component/index'
import {
    Home,
    About,
    Contact,
    Services,
    Solutions,
    OurTechnologies,
    WebDevelopment,
    SoftwareDevelopment,
    AppDevelopment,
    UserExperience,
} from '../Screens/index'
import { Works } from '../Component/index';
import {
    HOME,
    ABOUT,
    SERVICE,
    CONTACT,
    PROJECT,
    WEWORK,
    TECHNOLOGIES,
    WEB_DEVELOPMENT,
    SOFTWARE_DEVELOPMENT,
    MOBILE_APP,
    USER_EXPERIENCE,
} from './path'

const ROUTES = () => {
    return (
        <>
            <Navbar />
            <Routes>
                <Route path={HOME} element={<Home />} />
                <Route path={TECHNOLOGIES} element={<OurTechnologies />} />
                <Route path={ABOUT} element={<About />} />
                <Route path={SERVICE} element={<Services />} />
                <Route path={CONTACT} element={<Contact />} />
                <Route path={PROJECT} element={<Solutions />} />
                <Route path={WEWORK} element={<Works />} />
                <Route path={WEB_DEVELOPMENT} element={<WebDevelopment />} />
                <Route path={SOFTWARE_DEVELOPMENT} element={<SoftwareDevelopment />} />
                <Route path={MOBILE_APP} element={<AppDevelopment />} />
                <Route path={USER_EXPERIENCE} element={<UserExperience />} />
            </Routes>
            <ScrollBar />
            <Footer />
        </>
    )
}

export default ROUTES
