import cost_img from '../../assets/Image/cost_img.png'
import skill_img from '../../assets/Image/skill_img.png'
import acceleration_img from '../../assets/Image/acceleration_img.png'

const SoftwareDevelopment = () => {
    let software_development_obj = [
        {
            img: skill_img,
            h1: 'Close Skills Gaps',
            p: 'we are always on top to stay ahead of emerging technologies to deliver the expertise you need.'
        },
        {
            img: cost_img,
            h1: 'Reduce Costs',
            p: "New technology doesn't mean more expensive. Our software can be built for any solution."
        },
        {
            img: acceleration_img,
            h1: 'Accelerated Deployment',
            p: "our agile approach eliminates your backlog and reduces time to market."
        },
    ]
    return (
        <>
            <div className="development_main_div">
                <div className='development_heading_div'>
                    <h1>Software Development</h1>
                    <p>full-stack web application development services.</p>
                </div>
                <div className="development_inner_div">
                    <div className="development_inner_div_2">
                        <h4>Get a world-class</h4>
                        <h3>Software development partner</h3>
                        <p>
                            Our team has the experience it takes to make things happen — we've done it all before, and we're ready to do it again. With more than ten years of combined experience and a thriving culture, our team can create the game-changing software that you need in an affordable and efficient way. So our agile software development teams lead the way in full-stack web application development. We use the very best technologies to deliver high quality, enterprise-grade digital experiences that are beautiful and business-savvy. Our teams love solving problems head-on, and we're always up for a challenge.
                        </p>
                    </div>
                    <div className='development_card_main_div'>
                        {
                            software_development_obj?.map((v, i) => {
                                return (
                                    <>
                                        <div className='development_inner_main_div'>
                                            <div className='development_img_div  flip-card'>
                                                <div className='development_img_div flip-card-inner' >
                                                    <img src={v?.img} alt="" />
                                                </div>
                                            </div>
                                            <h1 className='development_card_h1'>
                                                {v?.h1}
                                            </h1>
                                            <p className='development_card_p'>
                                                {v?.p}
                                            </p>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default SoftwareDevelopment