import { BiRightArrowCircle } from "react-icons/bi"
import Button from "../Button/Button"
import { Link } from "react-router-dom"

const Schedule = (props) => {
    const { heading, heading2, bold, bold2 } = props

    return (
        <>
            <div className='schedule_inner_div'>
                <div className='solution_list_text_div3'>
                    <h4> {heading} </h4>
                    <b> {bold} </b>
                </div>
                <div className='solution_list_text_div5'>
                    <h4>{heading2}</h4>
                    <b>{bold2}</b>
                </div>
                <div className="schedule_btn_div">
                <Link to="/contact" className="button_link"><Button title="GET IN TOUCH WITH US" icon={<BiRightArrowCircle size={18} />} />
                </Link></div>
            </div>
        </>
    )
}
export default Schedule