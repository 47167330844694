import userResearch from '../../assets/Image/user_research_img.png'
import prototype from '../../assets/Image/prototype_img.png'
import unified_experience from '../../assets/Image/unified_experience.png'
const UserExperience = () => {

    return (
        <>
            <div className="development_main_div">
                <div className='development_heading_div'>
                    <h1>User Experience</h1>
                    <p>an engaging, seamless user experience is the surest path to business success</p>
                </div>
                <div className="development_inner_div">
                    <div className="development_inner_div_2">
                        <h3>We Will Build Your Prototype In Weeks—Not Months</h3>
                        <p>
                            We use our tried and tested range of methodologies to uncover the problems that can stop your company from reaching your goals. From rapid prototyping whiteboards, to more in-depth eye-tracking, we'll give you all the information you need to create a more engaging site that leads to more conversions. We use eye tracking, biometric response measurement and other tools to help you understand how customers react to certain features and offers.
                        </p>
                        <p>
                            At Consumer Crafts, we use the power of human-centered design to find the right solutions for your business. We use insights from research and user testing to create experiences that are simple, pleasurable and ultimately make a difference in your bottom line.
                        </p>
                        <p>
                        In today's world of online shopping, no business is an island! Buzztouch has leveraged its time-tested customer journey mapping and service design expertise to develop a solution for businesses that helps them engage with customers across all channels. Our solutions include customer relationship management, live chat, and help desk tools.
                        </p>
                        <p>
                            We test everything. We are just like a startup, and we're able to do that thanks to our low-risk, high-return approach of rapidly iterating between design, development, and testing. It involves proven AB testing approaches.
                        </p>
                    </div>
                    <div className="user_experience_cards_main">
                        <div className="user_experience_cards user_experience_cards_one">
                        <div className="circle_img_div">
                            <img src={userResearch} alt="" />
                        </div>
                            <h3>User Research</h3>
                            <p>Using lean experiments, design thinking, and research methods rooted in psychology and neuroscience, we identify your target audience and uncover critical insights about your customers quickly and efficiently</p>
                        </div>
                        <div className="user_experience_cards user_experience_cards_two">
                        <div className="circle_img_div">
                            <img src={unified_experience} alt="" />
                        </div>
                            <h3>Unified Experiences</h3>
                            <p>Each touchpoint is an opportunity to foster customer loyalty (or damage it). So our experts in customer journey mapping and service design help you create a seamless experience across digital channel.</p>
                        </div>
                        <div className="user_experience_cards">
                        <div className="circle_img_div">
                            <img src={prototype} alt="" />
                        </div>
                            <h3>Prototype</h3>
                            <p>Using a latest iterative approach and our proven design sprint process, we build prototypes to test with real customers with A/B Testing. This low-risk, high-value strategy help you out before making a major investment.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserExperience