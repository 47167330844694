import React, { useState, useEffect } from "react";
import { Button, SimpleSlider, Technologies, Schedule, Partic } from "../../Component";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import {
  FaHandsHelping,
  FaGlobe,
  FaBezierCurve,
  FaRocket,
  FaShoppingCart,
  FaLaptopHouse,
  FaNewspaper,
  FaRegTimesCircle,
} from "react-icons/fa";
import { BiRightArrowCircle } from "react-icons/bi";
import {
  AiOutlineExpand,
  AiFillCaretLeft,
  AiOutlineSearch,
  AiFillGift,
} from "react-icons/ai";
import { RiWechatLine, RiComputerLine } from "react-icons/ri";
import { TiImage } from "react-icons/ti";
import { FaRegHandshake } from "react-icons/fa";
import img1 from "../../assets/Image/img1.webp";
import img2 from "../../assets/Image/img2.webp";
import img3 from "../../assets/Image/img3.webp";
import img4 from "../../assets/Image/img4.webp";
import img5 from "../../assets/Image/img5.webp";
import img6 from "../../assets/Image/img6.webp";
import img7 from "../../assets/Image/img7.svg";
import img8 from "../../assets/Image/img8.png";
import img9 from "../../assets/Image/img9.svg";
import img10 from "../../assets/Image/img10.webp";
import img11 from "../../assets/Image/img11.webp";
import img12 from "../../assets/Image/img12.webp";
import img13 from "../../assets/Image/img13.webp";
import workGif from "../../assets/Image/workgif.gif";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import solution_svg from "../../assets/Image/solution_svg.svg";
import subscribe_img from "../../assets/Image/subscribe_img.webp";
import { Link } from "react-router-dom";
import { Link as ScrollLink, Element } from 'react-scroll';

const Home = () => {
  const [scrollY, setScrollY] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [card, setCard] = useState(false);
  const [chooseCards1, setChooseCard1] = useState(false);
  const [chooseCards2, setChooseCard2] = useState(false);
  const [chooseCards3, setChooseCard3] = useState(false);
  const texts = [
    "Innovative Software Solutions.",
    "Customized Development Services.",
    "Reliable Tech Expertise.",
    "Efficient Project Delivery.",
    "User-Friendly Interfaces.",
    "Secure Data Management.",
    "Custom API Integration..",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((index) => (index + 1) % texts.length);
    }, 1500);
    return () => clearInterval(interval);
  }, []);
  useScrollPosition(({ currPos }) => {
    setScrollY(-currPos.y);
  });
  return (
    <>
      <div className="main">
        <div className="text_container">
          <h1 className="heading_one">Simplifying Complexity,</h1>
          <h1 className="heading_two"> Amplifying Efficiency</h1>
          <div className="text_div">
            <p className="text">
              Your Reliable Collaboration for <b>Advanced IT Solutions.</b>{" "}
              Providing top-notch technology to businesses to enable them.
            </p>
          </div>
          <div className="partner_div">
            <h1 className="partner_text">Empowering Your Business with </h1>
            <span className="change_text">{texts[textIndex]}</span>
          </div>
          <div
            className="button_div"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
             <ScrollLink to="section" smooth={true} duration={500}><Button
              title="LET'S BEGIN"
              icon={<ArrowCircleDownIcon sx={{ fontSize: 17 }} />}
            /></ScrollLink>
          </div>
        </div>
        <div>
          <Partic />
        </div>
      </div>
      <Element name="section"><div className="service_container">
        <div className="service_heading_div">
          <h3 id="services">
            OUR <b>SERVICES</b>
          </h3>
          <p>
            <span className="provide">Driving Digital Transformation with</span>{" "}
            Expert IT Services.
          </p>
        </div>
        <div className="service_div">
          <div className="service_text">
            <h3
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              OUR <b>STRATEGY</b>
            </h3>
            <div
              className="inner_text_div"
              data-aos="fade-rigth"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <div className="icon_and_text_div">
                <span>
                  <EmojiObjectsOutlinedIcon sx={{ fontSize: 17 }} />
                </span>
                <p>
                  <span className="inner_text">
                    IT Infrastructure Solutions
                  </span>{" "}
                  Streamline your organization's technology infrastructure with
                  comprehensive solutions, including network design, hardware
                  implementation, cloud computing, and system integration.
                </p>
              </div>
              <div className="icon_and_text_div">
                <span>
                  <WorkHistoryOutlinedIcon sx={{ fontSize: 17 }} />
                </span>
                <p>
                  <span className="inner_text">
                    Software Development and Custom Applications
                  </span>{" "}
                  Leverage expertise in software development to create tailored
                  applications, web platforms, and mobile solutions that address
                  your specific business needs, enhancing productivity,
                  efficiency, and user experience.
                </p>
              </div>
              <div className="icon_and_text_div">
                <span>
                  <FaHandsHelping />
                </span>
                <p>
                  <span className="inner_text">
                    IT Consulting and Advisory Services
                  </span>{" "}
                  Benefit from strategic guidance and expert insights with IT
                  consulting services, which cover technology roadmap
                  development, IT optimization, IT project management, IT risk
                  assessment, and technology vendor evaluation.
                </p>
              </div>
            </div>
            <div
              className="service_btn_div"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
               <Link to="/about" className="button_link"><Button
                title="KNOW MORE ABOUT US"
                icon={<BiRightArrowCircle sx={{ fontSize: 17 }} />}
              /></Link>
            </div>
          </div>
          <div className="service_card_main">
            <div
              className="service_card"
              data-aos="fade-down"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <div>
                <span className="globe_icon">
                  <FaGlobe size={30} />
                </span>
                <h3 className="web3_text">
                  IT CONSULTING FOR NEXT-GENERATION TECHNOLOGIES
                </h3>
                <p>Our end-to-end holistic consulting services.</p>
              </div>
            </div>
            <div className="blue_service_card">
              <div>
                <span className="white_globe_icon">
                  <FaGlobe size={30} />
                </span>
                <h3 className="web3_text"> IT CONSULTING FOR NEXT-GENERATION TECHNOLOGIES</h3>
                <p className="blue_card_text">
                  We collaborate closely with your organization to understand
                  your unique requirements, industry landscape, and growth
                  objectives. Through in-depth consultations, we identify
                  opportunities for leveraging next-generation technologies to
                  drive innovation, streamline operations, enhance customer
                  experiences, and achieve sustainable growth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div></Element>
      <div className="choose_us_main">
        <div className="choose_us_container">
          <div className="choose_us_left_div">
            <div
              className="choose_us_heading_div"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <h3>
                WHY <b>US?</b>
              </h3>
              <p className="choose_us_text">
                <span className="provide">Grow your knowledge &</span> stay
                ahead of your competitors
              </p>
            </div>
            <div className="card_section">
              <div className="choose_us_card_main">
                <div
                  className="choose_us_card"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  <div>
                    <span className="no">4</span>
                    <p className="choose_us_card_text">
                      YEARS OF EXPERIENCE
                    </p>
                  </div>
                  <div>
                    <span className="no">0</span>
                    <p className="choose_us_card_text">PARTNER</p>
                  </div>
                  <div>
                    <span className="no">9</span>
                    <p className="choose_us_card_text">INDUSTRY EXPERTS</p>
                  </div>
                  <div>
                    <span className="no">10+</span>
                    <p className="choose_us_card_text">LANGUAGES</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="choose_us_right_div">
            {!chooseCards1 ? (
              <div onMouseOver={() => setChooseCard1(true)}>
                <span className="globe_icon">
                  <FaBezierCurve size={30} />
                </span>
                <h3 className="holistic_heading">INNOVATION & ADAPTABILITY</h3>
              </div>
            ) : (
              <div
                onMouseLeave={() => setChooseCard1(false)}
                className="choose_hover_card"
              >
                <span className="white_globe_icon">
                  <FaBezierCurve size={25} />
                </span>
                <p className="choose_us_card_hover_text">
                In the fast-paced world of technology, staying ahead of the curve is essential. Neptunes Tech embraces innovation and fosters a culture of continuous learning. We constantly explore emerging technologies and industry trends to ensure we offer cutting-edge solutions to our clients. With our adaptability and forward-thinking mindset, we help businesses navigate the rapidly evolving IT landscape, enabling them to leverage new opportunities and stay competitive in their respective markets.
                </p>
              </div>
            )}
            {!chooseCards2 ? (
              <div
                onMouseOver={() => setChooseCard2(true)}
                className="extensive_div"
              >
                <span className="globe_icon">
                  <AiOutlineExpand size={30} />
                </span>
                <h3 className="holistic_heading">EXTENSIVE EXPERTISE</h3>
              </div>
            ) : (
              <div
                onMouseLeave={() => setChooseCard2(false)}
                className="choose_hover_card"
              >
                <span className="white_globe_icon">
                  <AiOutlineExpand size={25} />
                </span>
                <p className="choose_us_card_hover_text">
                  Neptunes Tech boasts a team of highly skilled and experienced IT consultants. With a deep understanding of industry best practices, emerging technologies, and evolving trends, our consultants possess the knowledge to deliver effective solutions tailored to meet the unique needs of our clients. We leverage our expertise to provide comprehensive IT consulting services, guiding businesses towards optimal technology strategies and helping them achieve their goals.
                </p>
              </div>
            )}
            {!chooseCards3 ? (
              <div
                onMouseOver={() => setChooseCard3(true)}
                className="direct_div"
              >
                <span className="globe_icon">
                  <FaRegHandshake size={30} />
                </span>
                <h3 className="holistic_heading">CLIENT-CENTRIC APPROACH</h3>
              </div>
            ) : (
              <div
                onMouseLeave={() => setChooseCard3(false)}
                className="choose_hover_card"
              >
                <span className="white_globe_icon">
                  <FaRegHandshake size={25} />
                </span>
                <p className="choose_us_card_hover_text">
                At Neptunes Tech, we prioritize our clients and their success. We take a client-centric approach, actively listening to their challenges, goals, and objectives. By truly understanding their business operations, we develop customized IT solutions that align with their specific requirements. Our focus is on delivering tangible and measurable results that enhance efficiency, productivity, and overall business performance.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="client_feedback_container">
        <div
          className="client_heading_div"
          data-aos="fade-rigth"
          data-aos-duration="1500"
          data-aos-delay="200"
        >
          <h3>
            CLIENT <b>FEEDBACK</b>
          </h3>
          <p>
            <span className="provide">
              What companies we worked with are saying
            </span>{" "}
            about our services
          </p>
        </div>
        <div className="slider_main">
          <div className="slider_div">
            <SimpleSlider />
          </div>
        </div>
      </div>
      <div className="teachno_none">
        {/* <Technologies
          headingone="SELECTED"
          headingtwo="TECHNOLOGIES"
          text1="Some of the"
          text2="blockchains we work with"
          img1={img1}
          img2={img2}
          img3={img3}
          img4={img4}
          img5={img5}
          img6={img6}
          img7={img7}
        /> */}
      </div>
      <div className="home_work_main">
        <div className="home_work_main_left">
          <div>
            <div className="work_heading_div">
              <h3>
                HOW WE <b>WORK</b>
              </h3>
              <p>
                <span className="provide">
                The steps to crafting a Neptunes Tech IT consulting solution that is
                </span>{" "}
                flexible & tailored to your needs
              </p>
            </div>
            <div className="gif_div">
              <img src={workGif} alt="" />
            </div>
            <div className="work_text_div">
              <p>
                <span className="provide">
                  Take a look at how we work and discover some
                </span>{" "}
                selected case studies
              </p>
            </div>
            <div
              className="work_button_div"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <Link to="/service" className="button_link"><Button
                title="DISCOVER CASE STUDIES"
                icon={<BiRightArrowCircle sx={{ fontSize: 17 }} />}/></Link>
            </div>
          </div>
        </div>
        <div className="home_work_main_right">
          <div className="scroll_and_text_div">
            <div className="scroller_div">
              <div className="sroller_icon">
                <RiWechatLine
                  className={scrollY >= 2200 ? "show" : "hide"}
                  size={30}
                />
              </div>
              <div className={scrollY >= 2250 ? "blue_line" : "line"}></div>
              <div className="sroller_icon">
                <AiOutlineSearch
                  className={scrollY >= 2300 ? "show" : "hide"}
                  size={30}
                />
              </div>
              <div className={scrollY >= 2350 ? "blue_line" : "line"}></div>
              <div className="sroller_icon">
                <RiComputerLine
                  className={scrollY >= 2400 ? "show" : "hide"}
                  size={30}
                />
              </div>
              <div className={scrollY >= 2450 ? "blue_line" : "line"}></div>
              <div className="sroller_icon">
                <FaRocket
                  className={scrollY >= 2500 ? "show" : "hide"}
                  size={25}
                />
              </div>
            </div>
            <div className="work_text_main">
              <div className="text_and_icon_div">
                <div className="icon_div">
                  <AiFillCaretLeft size={15} />
                </div>
                <div className="text_div">
                  <p className="work_heading">Discovery</p>
                  <p className="work_text">
                    In the discovery call we aim to get to know you and
                    understand your projects goals and objectives. We will use
                    this session to gain a better understanding of the product
                    you're building and ensure that our approach is the right
                    fit for you.
                  </p>
                </div>
              </div>
              <div className="text_and_icon_div">
                <div className="icon_div">
                  <AiFillCaretLeft size={15} />
                </div>
                <div className="text_div">
                  <p className="work_heading">Proposal</p>
                  <p className="work_text">
                    Using the information from the discovery call and documents
                    provided, we conduct an in-depth analysis of the project.
                    The aim is to come up with a possible solution as well as
                    define the structure of a partnership more clearly.
                  </p>
                </div>
              </div>
              <div className="text_and_icon_div">
                <div className="icon_div">
                  <AiFillCaretLeft size={15} />
                </div>
                <div className="text_div">
                  <p className="work_heading">Presentation</p>
                  <p className="work_text">
                    Upon completion of the proposal, we will take you through it
                    and outline our process, the outcomes and our suggestions.
                    We will then adapt it to suit your needs and budget.
                  </p>
                </div>
              </div>
              <div className="text_and_icon_div">
                <div className="icon_div">
                  <AiFillCaretLeft size={15} />
                </div>
                <div className="text_div">
                  <p className="work_heading">Delivery</p>
                  <p className="work_text">
                    We now deliver the material as agreed upon in the proposal.
                    We keep in touch with you along the delivery process and
                    give you updates after each iteration. Once the project is
                    finished we are available for post implementation support.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Technologies
          headingone="OUR MOST RECENT"
          headingtwo="CLIENTS"
          text1="Trusted by a wide range of"
          text2="projects"
          img8={img8}
          img9={img9}
          img10={img10}
          img11={img11}
          img12={img12}
          img13={img13}
        />
      </div>
      {/* <div className="solution_in_home_main">
        <div className="solution_in_home_inner_div">
          <div className="solution_text_main_div_home">
            <div
              className="solution_list_text_div2"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <h4>OUR</h4>
              <b>SOLUTIONS</b>
            </div>
            <div className="solution_text_and_icons_div">
              <div className="solution_text_and_icons_inner_div">
                <span>
                  <TiImage size={18} />
                </span>
                <p className="solution_text_in_home">Digital Collectibles</p>
              </div>
              <div className="solution_text_and_icons_inner_div">
                <span>
                  <AiFillGift size={18} />
                </span>
                <p className="solution_text_in_home">NFT Loyalty Programs</p>
              </div>
              <div className="solution_text_and_icons_inner_div">
                <span>
                  <AiOutlineSearch size={16} />
                </span>
                <p className="solution_text_in_home">NFT Marketplace</p>
              </div>
              <div className="solution_text_and_icons_inner_div">
                <span>
                  <FaLaptopHouse size={18} />
                </span>
                <p className="solution_text_in_home">Metaverse Experience</p>
              </div>
            </div>
            <div
              className="discover_solution_btn"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <Button
                title="DISCOVER SOLUTIONS"
                icon={<BiRightArrowCircle sx={{ fontSize: 17 }} />}
              />
            </div>
          </div>
          <div className="solution_text_and_gif_main_div_home">
            <div>
              <div
                className="solution_list_text_div3"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-delay="200"
              >
                <h4>A WIDE RANGE OF</h4>
                <b> WEB3 SOLUTIONS & EXPERIENCES</b>
              </div>
              <div className="solution_list_text_div4">
                <h4>Discover some potential </h4>
                <b> Web3 products you could implement</b>
              </div>
              <div className="solution_svg_div">
                <img src={solution_svg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="subscribe_main">
        <div className="subscribe_img_div">
          <img src={subscribe_img} alt="" />
        </div>
        <div className="subscribe_text_div">
          <div
            data-aos="fade-rigth"
            data-aos-duration="1500"
            data-aos-delay="200"
          >
            <h3 className="subscribe_heading">
              SUBSCRIBE TO <b>NEPTUNES TECH</b> NEWSLETTER
            </h3>
            <p>
              <span className="provide">Get exclusive access </span> to our valuable insights into the IT consulting landscape.
            </p>
            <div className="solution_text_and_icons_div subscribe_text_and_icons_div">
              <div className="solution_text_and_icons_inner_div subscribe_text_and_icons_inner_div">
                <span>
                  <FaNewspaper size={16} />
                </span>
                <p className="solution_text_in_home subscribe_text">
                Tailored IT Consulting Solutions
                </p>
              </div>
              <div className="solution_text_and_icons_inner_div subscribe_text_and_icons_inner_div">
                <span>
                  <AiFillGift size={18} />
                </span>
                <p className="solution_text_in_home subscribe_text">
                In-Depth Analyses
                </p>
              </div>
              <div className="solution_text_and_icons_inner_div subscribe_text_and_icons_inner_div">
                <span>
                  <FaRegTimesCircle size={15} />
                </span>
                <p className="solution_text_in_home subscribe_text">No Unwanted Emails</p>
              </div>
            </div>
          </div>
          <div
            className="subscribe_input_and_btn_div"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <input
              type="email"
              placeholder="vitalik.buterin@binance.com"
              className="subscribe_input"
            />
            <button className="subscribe_btn">Subscribe</button>
          </div>
        </div>
      </div>
      <div className="schedule_main_div">
        <Schedule
          heading="SCHEDULE A CALL OR"
          bold="CONTACT US NOW"
          heading2="We'd love to"
          bold2=" hear about your project!"
        />
      </div>
    </>
  );
};

export default Home;