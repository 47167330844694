import { FaThList } from 'react-icons/fa'
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { BsTelegram } from 'react-icons/bs'
import digital_comm from '../../assets/Image/digital_comm.png'
import web_deve from '../../assets/Image/web_deve.png'
import website_dev from '../../assets/Image/website_dev.png'
import ui_ux from '../../assets/Image/ui_ux.png'
import domain_hosting from '../../assets/Image/domain_hosting.png'
import app_dev from '../../assets/Image/app_dev.png'
import { MOBILE_APP, SOFTWARE_DEVELOPMENT, USER_EXPERIENCE, WEB_DEVELOPMENT } from '../../Config/path';
import { Link, useNavigate } from 'react-router-dom';

const ServiceHover = (props) => {
    const { para1, title1, para2, para2a, title2, para3, title3, para4, title4, para5, title5, para6, para3a, para4a, para5a, para6a, title7, para8, para8a, title10, para11, para11a } = props
    let navigate = useNavigate()
    let icons = [
        {
            icon: <EmailIcon className='navbar_icon' />,
        },
        {
            icon: <TwitterIcon className='navbar_icon' />,
        },
        {
            icon: <YouTubeIcon className='navbar_icon' />,
        },
        {
            icon: <LinkedInIcon className='navbar_icon' />,
        },
        {
            icon: <BsTelegram className='navbar_icon' />,
        },
    ]

    let nav_card = [
        // {
        //     img: digital_comm,
        //     title: title1,
        //     para2: para2,
        //     para2a: para2a,
        // },
        {
            img: web_deve,
            title: title2,
            para2: para3,
            para2a: para3a,
            link: SOFTWARE_DEVELOPMENT
        },
        {
            img: website_dev,
            title: title4,
            para2: para5,
            para2a: para5a,
            link: WEB_DEVELOPMENT,
        },
        {
            img: app_dev,
            title: title5,
            para2: para6,
            para2a: para6a,
            link: MOBILE_APP,
        },
        {
            img: ui_ux,
            title: title7,
            para2: para8,
            para2a: para8a,
            link: USER_EXPERIENCE,
        },
        {
            img: domain_hosting,
            title: title10,
            para2: para11,
            para2a: para11a,
        },
    ]

    return (
        <>
            <div className='navbar_hover_main_div'>
                <div className='navbar_hover_div'>
                    <div className='navbar_hover_inner_div'>
                        <div className='list_icon_div'>
                            <FaThList />
                        </div>
                        <h3>SOLUTIONS</h3>
                    </div>
                    <div className="navbar_hover_inner_div2">
                        <p>{para1}</p>
                    </div>
                    <div>
                        {
                            icons?.map((v, i) => {
                                return (
                                    <>
                                        <div className='navbar_icon_inner_div'>
                                            {v?.icon}
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='navbar_hover_div2'>
                    {
                        nav_card?.map((v, i) => {
                            return (
                                <>
                                    <Link to={v?.link} className='nav_hover_card flip-card'>
                                        <div class="flip-card-inner">
                                            {/* <div className='flip-card-front'> */}

                                            <div className='nav_hover_icon_div2'>
                                                <img src={v?.img} alt="" />
                                            </div>
                                            <div className='nav_hover_text_div' >
                                                <h3>
                                                    {v?.title}
                                                </h3>
                                                <p>
                                                    {v?.para2}
                                                </p>
                                            </div>
                                            {/* </div> */}

                                            <div className='flip-card-back'>
                                                <div className='nav_hover_card_p2'>
                                                    <h3>
                                                        {v?.title}
                                                    </h3>
                                                    <p>
                                                        {v?.para2a}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </>
                            )
                        })
                    }
                </div>
            </div>

        </>
    )
}
export default ServiceHover