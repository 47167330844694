import { app_tech_obj } from "../../utils/Constant"

const App_Tech = () => {
    return (
        <>
            <div className="technologies_main_div">
                <h2 className="technologies_h2">
                    App Technologies
                </h2>
                <p className="technologies_p">
                    Building apps for the web and mobile require a whole new set of abilities. These
                    technologies empower with those abilities.
                </p>
                <div className="technologies_card_main_div">
                    {app_tech_obj?.map((v, i) => {
                        return (
                            <>
                                <div className="technologies_card_inner_div">
                                    <img src={v?.img} />
                                </div>
                            </>
                        )
                    })
                    }
                </div>
            </div>
        </>
    )
}
export default App_Tech