import user_research_img from '../../assets/Image/user_research_img.png'
import design_img from '../../assets/Image/design_img.png'
import prototype_img from '../../assets/Image/prototype_img.png'
import development_img from '../../assets/Image/development_img.png'
import devops_img from '../../assets/Image/devops_img.png'
import security_img from '../../assets/Image/security_img.png'

const AppDevelopment = () => {
    let app_development_obj = [
        {
            img: user_research_img,
            h1: 'User Research',
        },
        {
            img: design_img,
            h1: 'Design',
        },
        {
            img: prototype_img,
            h1: 'Prototype',
        },
        {
            img: development_img,
            h1: 'Development',
        },
        {
            img: devops_img,
            h1: 'Devops',
        },
        {
            img: security_img,
            img: security_img,
            h1: 'Continuous Security',
        },
    ]
    return (
        <>
            <div className="development_main_div">
                <div className='development_heading_div'>
                    <h1>Mobile App Development</h1>
                    <p>Enterprise mobility is key to digital transformation</p>
                </div>
                <div className="development_inner_div">
                    <div className="development_inner_div_2">
                        <h3>A Team That Specializes In Making Our Clients' Vision A Reality, Quickly.</h3>
                        <p>
                            Time to market is critical for success in mobile. Businesses must ensure that the quality of their product stays high as well. Manifest Sciences helps businesses envision, design, and produce a mobile-first product within budget and on schedule by providing them with the tools they need to succeed.
                        </p>
                    </div>
                    <div className='app_card_main_div'>
                        {
                            app_development_obj?.map((v, i) => {
                                return (
                                    <>
                                        <div className='app_card_inner_div'>
                                            <div className='app_card_img_div'>
                                                <img src={v?.img} alt="" />
                                            </div>
                                            <h1 className='app_card_h1'>
                                                {v?.h1}
                                            </h1>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppDevelopment