import { web_tech_obj } from "../../utils/Constant"

const WebTechnologies = () => {
    return (
        <>
            <div className="technologies_main_div">
                <h2 className="technologies_h2">
                    Web Technologies
                </h2>
                <p className="technologies_p">
                    Building apps for the web and mobile require a whole new set of abilities. These
                    technologies empower with those abilities.
                </p>
                <div className="technologies_card_main_div">
                    {web_tech_obj?.map((v, i) => {
                        return (
                            <>
                                <div className="technologies_card_inner_div">
                                    <img src={v?.img} width={v?.width} />
                                </div>
                            </>
                        )
                    })
                    }
                </div>
            </div>
        </>
    )
}
export default WebTechnologies