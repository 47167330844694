import React from 'react'
import { FaThList, FaHammer, FaSearch, FaServer, FaGlobe, FaChartBar, FaBookOpen } from 'react-icons/fa'
import digital_comm from '../../assets/Image/digital_comm.png'
import web_deve from '../../assets/Image/web_deve.png'
import website_dev from '../../assets/Image/website_dev.png'
import ui_ux from '../../assets/Image/ui_ux.png'
import domain_hosting from '../../assets/Image/domain_hosting.png'
import app_dev from '../../assets/Image/app_dev.png'
import service_svg from '../../assets/Image/service_svg.svg'
import { Schedule } from '../../Component'
import { MOBILE_APP, SOFTWARE_DEVELOPMENT, USER_EXPERIENCE, WEB_DEVELOPMENT } from '../../Config/path';
import { Link } from 'react-router-dom'

const Services = () => {

    let service_obj = [
        {
            img: web_deve,
            title: 'Software Development',
            para2: 'Solutions that build businesses.',
            para2a: 'Crafting innovative software solutions to power businesses and drive growth.',
            link: SOFTWARE_DEVELOPMENT

        },
        {
            img: website_dev,
            title: 'Website Development',
            para2: 'Affordable websites that work.',
            para2a: 'Creating stunning websites that captivate audiences and drive online success.',
            link: WEB_DEVELOPMENT,

        },
        {
            img: app_dev,
            title: 'Mobile Application',
            para2: 'Our solution is your success.',
            para2a: 'Building user-friendly mobile applications for seamless digital experiences.',
            link: MOBILE_APP,

        },
        {
            img: ui_ux,
            title: 'UI/UX Designing',
            para2: 'It’s how the world sees you!',
            para2a: 'Creating intuitive and visually appealing UI/UX designs for exceptional user experiences.',
            link: USER_EXPERIENCE,

        },
        {
            img: domain_hosting,
            title: 'Domain & Hosting',
            para2: 'Affordable websites that work.',
            para2a: 'Providing reliable domain and hosting services for a seamless online presence.',
        },
    ]

    return (
        <>
            <div className='solution_main_div'>
                <div>
                    <div className='solution_list_icon_div'>
                        <FaHammer />
                    </div>
                    <div className='solution_list_text_div'>
                        <h4>OUR</h4>
                        <b>SERVICES</b>
                    </div>
                </div>
                <div className='solution_inner_div1'>
                    <div className='solution_text_main_div'>
                        <div className='solution_list_text_div2' data-aos="fade-right" data-aos-duration='1500' data-aos-delay='200'>
                            <h4>OUR</h4>
                            <b>SERVICES</b>
                        </div>
                        <div className='solution_text_div'>
                            <p className='solution_p'>
                                We firmly believe in the growing importance of blockchain technology in today's digital world. Thus, we offer a wide range innovative services to help you leverage this technology to your advantage. Whether you're looking to create digital collectibles, use blockchain-based ticketing solutions, or develop NFT loyalty programs, we have the expertise to help.
                            </p>
                            <p className='solution_p2'>
                                Additionally, we offer OSINT (open-source intelligence) services, which provide blockchain analytics to help you gain insights and make data-driven decisions. With our DAO and validator setup services we help you to decentralize your business practices and establish fully transparent and decentralized decision-making processes with efficiency and security in mind.
                            </p>
                        </div>
                    </div>
                    <div className='solution_text_main_div2'>
                        <div>
                            <div className='service_list_text_div3' data-aos="fade-left" data-aos-duration='1500' data-aos-delay='200'>
                                <h4>EMBRACE THE FUTURE OF IT WITH</h4>
                                <b>NEPTUNES TECH</b>
                            </div>
                            <div className='solution_list_text_div4'>
                                <h4>Explore a wide range of</h4>
                                <b>IT consulting services offered by Neptunes Tech.</b>
                            </div>
                            <div className='solution_svg_div'>
                                <img src={service_svg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cards_main_div2'>
                    {
                        service_obj?.map((v, i) => {
                            return (
                                <Link className='card_inner_div2' to={v?.link}>
                                    <div className='service_card_img_div'>
                                        <img src={v?.img} alt="" />
                                    </div>
                                    <div className='card_h3_div2'>
                                        <h3>
                                            {v?.title}
                                        </h3>
                                        <p>
                                            {v?.para2}
                                        </p>
                                    </div>
                                    <div className='card_h3_div3'>
                                        <h3>
                                            {v?.title}
                                        </h3>
                                        <p>
                                            {v?.para2}
                                        </p>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
                <div className='schedule_main_div'>
                    <Schedule heading="CAN'T FIND THE" bold="SERVICE YOU NEED?" heading2="Schedule a call or" bold2=' send us a message!' />
                </div>
            </div>
            </>
            )
}
            export default Services