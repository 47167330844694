import { FaThList/*, FaShoppingCart, FaLaptopHouse */ } from "react-icons/fa";
// import { BiImage } from "react-icons/bi";
// import { BsFillGiftFill } from "react-icons/bs";
import solution_svg from "../../assets/Image/solution_svg.svg";
import { Schedule } from "../../Component"
import { projects } from '../../utils/Constant'
import { AiOutlineLink } from "react-icons/ai";

const Solutions = () => {
  return (
    <>
      <div className="solution_main_div">
        <div>
          <div className="solution_list_icon_div">
            <FaThList />
          </div>
          <div className="solution_list_text_div">
            <h4>OUR</h4>
            <b>PROJECTS</b>
          </div>
        </div>
        <div className="solution_inner_div1">
          <div className="solution_text_main_div">
            <div
              className="solution_list_text_div2"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <h4>OUR</h4>
              <b>PROJECTS</b>
            </div>
            <div className="solution_text_div">
              <p className="solution_p">
                Our dedicated team of IT consultants, project managers, and
                technical experts collaborated closely with our clients
                throughout the project. We fostered strong partnerships,
                ensuring that the solutions we developed were tailored to their
                specific needs. The project's success is a testament to the
                synergy between our team and our clients' teams.
              </p>
              <p className="solution_p2">
                Our aim is to continue helping organizations embrace emerging
                technologies, capitalize on new opportunities, and stay ahead of
                the competition in an ever-evolving digital landscape.
              </p>
            </div>
          </div>
          <div className="solution_text_main_div2">
            <div>
              <div
                className="solution_list_text_div3"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-delay="200"
              >
                <h4>Driving Digital Transformation with</h4>
                <b> TAILORED IT SOLUTIONS</b>
              </div>
              <div className="solution_list_text_div4">
                <h4>Explore some of the innovative </h4>
                <b> projects we have successfully delivered</b>
              </div>
              <div className="solution_svg_div">
                <img src={solution_svg} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="cards_main_div">
          <div className="cards_main_div">
            {
              projects?.map((v) => {
                return (
                  <div className="card_inner_div">
                    <div className="card_icon_div">
                      {v.icon}
                    </div>
                    <div className="card_h3_div">
                      <h3>{v?.name}</h3>
                    </div>
                    <div>
                      <h3>
                        <AiOutlineLink onClick={() => window.open(v?.url, '_blank')} /></h3>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="schedule_main_div">
          <Schedule
            heading="CAN'T FIND THE"
            bold="PRODUCT YOU NEED?"
            heading2="Schedule a call or"
            bold2=" send us a message!"
          />
        </div>
      </div>
    </>
  );
};
export default Solutions;