import React, { useEffect } from 'react';
import './App.css';
import ROUTES from './Config/routes';
import AOS from "aos";
import Works from './Component/HowWeWorks/Works';
import AppDevelopment from './Screens/Services/AppDevelopment';
function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
      <ROUTES />
      {/* <AppDevelopment /> */}
      
    </>
  );
}

export default App;

