import { FaThList, FaMapPin, FaNetworkWired } from 'react-icons/fa'
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { BsTelegram, BsClock } from 'react-icons/bs'
import { CiFlag1 } from 'react-icons/ci'
import Pak_img from '../../assets/Image/Pak_img.jpg'
import { HiOutlineBuildingOffice } from 'react-icons/hi2';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const About_Hover = () => {
    let icons = [
        {
            icon: <EmailIcon className='navbar_icon' />,
        },
        {
            icon: <TwitterIcon className='navbar_icon' />,
        },
        {
            icon: <YouTubeIcon className='navbar_icon' />,
        },
        {
            icon: <LinkedInIcon className='navbar_icon' />,
        },
        {
            icon: <BsTelegram className='navbar_icon' />,
        },
    ]

    let aboutIcons = [
        // {
        //     icon_s: <HiOutlineBuildingOffice />,
        //     title: 'COMPANY',
        //     para: 'Discover our mission, vision, and values, as we collaborate to create innovative solutions and make a positive impact in our industry.'
        // },
        {
            icon_s: <FaNetworkWired />,
            title: 'Work With Us',
            para: 'Experience the power of technology as it is leveraged by a group of professionals that are passionate about using innovation to create positive change.'
        },
        // {
        //     icon_s: <AiOutlineQuestionCircle />,
        //     title: 'FAQ',
        //     para: 'Find clear, concise answers to common queries, addressing your concerns and providing valuable insights to enhance your experience.'
        // },
    ]
    return (
        <>
            <div className='navbar_hover_main_div'>
                <div className='navbar_hover_div'>
                    <div className='navbar_hover_inner_div'>
                        <div className='list_icon_div'>
                            <FaThList />
                        </div>
                        <h3>WHAT WE DO</h3>
                    </div>
                    <div className="navbar_hover_inner_div3">
                        <div className='about_located_icon_div'>
                            <div className='about_inner_icon_div'>
                                <div><BsClock className='about_located_icon' /></div>
                                <div className='about_inner_p_div2'>
                                    <p>
                                        Covering most timezones
                                    </p>
                                </div>
                            </div>
                            <div className='about_inner_icon_div'>
                                <div><CiFlag1 className='about_located_icon' /></div>
                                <div className='about_inner_p_div2'>
                                    <p>
                                        Working with teams of diverse backgrounds
                                    </p>
                                </div>
                            </div>
                            <div className='about_inner_icon_div'>
                                <div><FaMapPin className='about_located_icon' /></div>
                                <div className='about_inner_p_div2'>
                                    <p>
                                        Based in Karachi, Pakistan
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p>Our group of knowledgeable experts is passionate about creating cutting-edge solutions that boost productivity and promote corporate success. We have the knowledge to meet your unique needs, whether you need software development, cloud solutions, cybersecurity services, IT consulting, or managed IT services. To make sure that our solutions are reliable, scalable, and in line with your company objectives, we make use of the newest technology and best practises in the industry.</p>
                        <div className='swit_div'>
                            <img src={Pak_img} alt="" />
                            <p>Karachi, Pakistan</p>
                        </div>
                    </div>
                    <div>
                        {
                            icons?.map((v, i) => {
                                return (
                                    <>
                                        <div className='navbar_icon_inner_div'>
                                            {v?.icon}
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='navbar_hover_div3'>
                    {
                        aboutIcons?.map((v, i) => {
                            return (
                                <Link to='/howWeWork' style={{ textDecoration: 'none' }}>
                                    <div className='nav_hover_card2 flip-card'>
                                        <div class="flip-card-inner">

                                            <div className='nav_hover_icon_div'>
                                                {v?.icon_s}
                                            </div>
                                            <div className='nav_hover_text_div' >
                                                <h3>
                                                    {v?.title}
                                                </h3>
                                            </div>


                                            <div className='nav_hover_card_p2 flip-card-back'>
                                                <p style={{ color: 'white' }}>
                                                    {v?.para}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
                <div className='navbar_hover_div4'>
                    
                    <iframe
                        title="Shaheed-e-Millat Rd, BMCHS Sharafabad, Karachi, Karachi City, Sindh, Pakistan"
                        loading="lazy"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3619.5010989997154!2d67.06124457515268!3d24.88088357791594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33eec7bb25631%3A0x5c41956655801d43!2sAl-Khaleej%20Tower%2C%20Shaheed-e-Millat%20Rd%2C%20BMCHS%20Sharafabad%2C%20Karachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1685993169483!5m2!1sen!2s"
                        width="100%"
                        height="430"
                        style={{ border: 0, borderRadius: '8px' }}
                        allowFullScreen
                    ></iframe>
                </div>
            </div>

        </>
    )
}
export default About_Hover