import { design_obj } from "../../utils/Constant"

const Design = () => {
    return (
        <>
            <div className="technologies_main_div">
                <h2 className="technologies_h2">
                    Design
                </h2>
                <p className="technologies_p">
                    From sketching prototypes to visualizing designs, we use a suite of design
                    products that give shape and form to ideas.
                </p>
                <div className="technologies_card_main_div">
                    {design_obj?.map((v, i) => {
                        return (
                            <>
                                <div className="technologies_card_inner_div">
                                    <img src={v?.img} />
                                </div>
                            </>
                        )
                    })
                    }
                </div>
            </div>
        </>
    )
}
export default Design