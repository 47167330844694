import { database_obj } from "../../utils/Constant"

const DataBase = () => {
    return (
        <>
            <div className="technologies_main_div">
                <h2 className="technologies_h2">
                    Database
                </h2>
                <p className="technologies_p">
                    Your data should reside in the right place where it is east to access and is secure
                    too. We have chosen the right database platforms to host your data after long
                    research and introspection.
                </p>
                <div className="technologies_card_main_div">
                    {database_obj?.map((v, i) => {
                        return (
                            <>
                                <div className="technologies_card_inner_div">
                                    <img src={v?.img} />
                                </div>
                            </>
                        )
                    })
                    }
                </div>
            </div>
        </>
    )
}
export default DataBase