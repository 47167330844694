import React from "react";

const Button = (props) => {
    const { title, icon } = props
    return (
        <div className="btn_div">
            <span className="btn">
                {title}
            </span>
            <span>{icon}</span>
        </div>
    );
}

export default Button;