const HOME = '/'
const ABOUT = '/about'
const SERVICE = '/service'
const CONTACT = '/contact'
const PROJECT = '/project'
const WEWORK = '/howWeWork'
const TECHNOLOGIES = '/technologies'
const WEB_DEVELOPMENT = '/web-development'
const SOFTWARE_DEVELOPMENT = '/software-development'
const MOBILE_APP = '/mobile-app'
const USER_EXPERIENCE = '/user-experience'

export {
    HOME,
    ABOUT,
    SERVICE,
    CONTACT,
    PROJECT,
    WEWORK,
    TECHNOLOGIES,
    SOFTWARE_DEVELOPMENT,
    WEB_DEVELOPMENT,
    MOBILE_APP,
    USER_EXPERIENCE,
}
