// import EmailIcon from "@mui/icons-material/Email";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import YouTubeIcon from "@mui/icons-material/YouTube";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import { BsTelegram } from "react-icons/bs";
// import { FiFacebook } from "react-icons/fi";

// const Footer = () => {
//   let icons = [
//     {
//       icon: <EmailIcon className="footer_icon" />,
//     },
//     {
//       icon: <TwitterIcon className="footer_icon" />,
//     },
//     {
//       icon: <FiFacebook className="footer_icon" />,
//     },
//     {
//       icon: <YouTubeIcon className="footer_icon" />,
//     },
//     {
//       icon: <LinkedInIcon className="footer_icon" />,
//     },
//     {
//       icon: <InstagramIcon className="footer_icon" />,
//     },
//     {
//       icon: <BsTelegram className="footer_icon" />,
//     },
//   ];

//   return (
//     <div className="footer_main_div">
//       <div className="footer_inner_div">
//         <div className="footer_div1">
//           <div className="footer_logo_div">
//             <img
//               src="https://i0.wp.com/blockconsult.org/wp-content/uploads/2022/10/blockconsult_light-2.png?fit=900%2C220&ssl=1"
//               alt=""
//             />
//           </div>
//           <div className="footer_p">
//             <p>
//               Block Consult is a Swiss-based consulting firm that provides value by creating holistic Web3 concepts tailored to the individual needs of any project.
//             </p>
//           </div>
//           <div className="footer_icon_div">
//             <a href="#" className="footer_a">
//               info@blockconsult.org
//             </a>
//             <ul className="footer_icon_ul">
//               {icons?.map((v, i) => {
//                 return (
//                   <div className="footer_icon_inner_div" key={i}>
//                     <li>{v?.icon}</li>
//                   </div>
//                 );
//               })}
//             </ul>
//           </div>
//         </div>
//         <div className="footer_div2">
//           <ul className="footer_ul">
//             <h3 className="footer_h3">COMPANY</h3>
//             <li>About</li>
//             <li>Contact</li>
//             <li>How We Work</li>
//             <li>Legal Notice</li>
//             <li>Privacy Policy</li>
//           </ul>
//         </div>
//         <div className="footer_div3">
//           <ul className="footer_ul">
//             <h3 className="footer_h3">SERVICES</h3>
//             <li>Solutions</li>
//             <li>Onboarding Course</li>
//             <li>Web3 Startups</li>
//             <li>Traditional End-to-end</li>
//             <li>Tokenomics</li>
//             <li>Validator Setup</li>
//             <li>DAO Setup</li>
//             <li>OSINT</li>
//           </ul>
//         </div>
//         <div className="footer_div4">
//           <ul className="footer_ul">
//             <h3 className="footer_h3">RESOURCES</h3>
//             <li>Blog</li>
//             <li>Podcast</li>
//             <li>Case Studies</li>
//             <li>FAQ</li>
//             <li>Lexicon</li>
//           </ul>
//         </div>
//       </div>
//       <div className="footer_inner_div2_main">
//         <div className="footer_inner_div2">
//           <div>
//             <p>Neptunes Tech</p>
//           </div>
//           <div>
//             <p>© All rights reserved</p>
//           </div>
//           <div className="switzerland_div">
//             <p className="zug">Zug, Switzerland</p>
//             <div className="plus_icon">
//               <img src={Pak_img} className="switzerland_img" alt="Switzerland" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Footer;
// import EmailIcon from "@mui/icons-material/Email";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import YouTubeIcon from "@mui/icons-material/YouTube";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import { BsTelegram } from "react-icons/bs";
// import { FiFacebook } from "react-icons/fi";
import React, { useEffect } from 'react';
import { ImHome } from "react-icons/im";
import {GrTechnology} from "react-icons/gr";
import {GrProjects} from "react-icons/gr";
import {FcServices} from "react-icons/fc";
import {FcAbout} from "react-icons/fc";
import {IoMdContact} from "react-icons/io"
import Pak_img from "../../assets/Image/Pak_img.jpg";
import {Link, useLocation} from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  let icons = [
    {
      icon: <Link to="/" onClick={() => window.scrollTo(0, 0)}><ImHome className="footer_icon" /></Link>
    },
    {
      icon: <Link to="/technologies" onClick={() => window.scrollTo(0, 0)}><GrTechnology className="footer_icon" /></Link>,
    },
    {
      icon: <Link to="/project" onClick={() => window.scrollTo(0, 0)}><GrProjects className="footer_icon" /></Link>,
    },
    {
      icon: <Link to="/service" onClick={() => window.scrollTo(0, 0)}><FcServices className="footer_icon" /></Link>,
    },
    {
      icon: <Link to="/about" onClick={() => window.scrollTo(0, 0)}><FcAbout className="footer_icon" /></Link>,
    },
    {
      icon: <Link to="/contact" onClick={() => window.scrollTo(0, 0)}><IoMdContact className="footer_icon" /></Link>,
    }
  ];

  return (
    <>
      <div className="footer_main_div">
        <div className="footer_inner_div">
          <div className="footer_div1">
            <div className="footer_logo_div">
              <h3 className="footer-logo">NEPTUNES TECH</h3>
              {/* <img src="https://i0.wp.com/blockconsult.org/wp-content/uploads/2022/10/blockconsult_light-2.png?fit=900%2C220&ssl=1" alt="" /> */}
            </div>
            <div className="footer_p">
              <p>
                Together, we can bring your ideas to life, validate their
                potential, and navigate the competitive IT landscape with
                confidence.
              </p>
            </div>
            <div className="footer_icon_div">
              <a href="#" className="footer_a">
                developers@neptunestech.com

              </a>
              {/* <ul className="footer_icon_ul">
                {icons?.map((v, i) => {
                  return (
                    <div className="footer_icon_inner_div">
                      <li>{v?.icon}</li>
                    </div>
                  );
                })}
              </ul> */}
           <ul className="footer_icon_ul">
      {icons?.map((v, i) => (
        <div className="footer_icon_inner_div" key={i}>
          {v.icon}
        </div>
      ))}
    </ul>
            </div>
          </div>
          {/* <div className="footer_div2">
            <ul className="footer_ul">
              <h3 className="footer_h3">COMPANY</h3>
              <li href="/About">About</li>
              <li>Contact</li>
              <li>How We Work</li>
              <li>Legal Notice</li>
              <li>Privacy Policy</li>
            </ul>
          </div>
          <div className="footer_div3">
            <ul className="footer_ul">
              <h3 className="footer_h3">SERVICES</h3>
              <li>Solutions</li>
              <li>Onboarding Course</li>
              <li>Web3 Startups</li>
              <li>Traditional End-to-end</li>
              <li>Tokenomics</li>
              <li>Validator Setup</li>
              <li>DAO Setup</li>
              <li>OSINT</li>
            </ul>
          </div>
          <div className="footer_div4">
            <ul className="footer_ul">
              <h3 className="footer_h3">RESOURCES</h3>
              <li>Blog</li>
              <li>Podcast</li>
              <li>Case Studies</li>
              <li>FAQ</li>
              <li>Lexicon</li>
            </ul>
          </div> */}
        </div>
      </div>
      <div className="footer_inner_div2_main">
        <div className="footer_inner_div2">
          <div>
            <p>Neptunes Tech</p>
          </div>
          <div>
            <p>© All rights reserved</p>
          </div>
          <div className="switzerland_div">
            <p className="zug">Karachi, Pakistan</p>
            <div className="plus_icon">
              <img src={Pak_img} className="switzerland_img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;