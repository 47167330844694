import story_img from '../../assets/Image/story_img.avif'
import Pak_img from '../../assets/Image/Pak_img.jpg'
import { Link } from 'react-router-dom';
import about_global_img from '../../assets/Image/about_global_img.webp'
import { GrLinkedinOption } from 'react-icons/gr';
import { FaTelegramPlane, FaMapPin } from 'react-icons/fa'
import { CiFlag1 } from 'react-icons/ci'
import { BsClock } from 'react-icons/bs'
import { EmailOutlined } from '@mui/icons-material'
import { Schedule } from '../../Component'
import { team_obj, partner_obj } from '../../utils/Constant'


const About = () => {

    return (
        <>
            <div className="about_main_div">
                <div className="about_inner_div1">
                    <div className='solution_list_text_div' data-aos="fade-right" data-aos-duration='1500' data-aos-delay='100'>
                        <h4>OUR</h4>
                        <b>COMPANY</b>
                    </div>
                    <p className="about_p">
                        Welcome to Neptunes Tech, where innovation meets excellence in the world of technology. As a leading IT-based company, we are committed to delivering cutting-edge solutions and empowering businesses to thrive in the digital era.
                    </p>
                </div>
                <div className="about_inner_div2">
                    <div className='story_div'>
                        <div className='about_img_div' data-aos="fade-right" data-aos-duration='1500' data-aos-delay='100'>
                            <img src={story_img} alt="" />
                        </div>
                        <div className='story_inner_div'>
                            <div className='about_h4_div'>
                                <h4>ABOUT</h4>
                                <b>US</b>
                            </div>
                            <div className='about_p1'>
                                <p>
                                    At Neptunes Tech, our mission is to revolutionize the way businesses operate by leveraging the power of technology. We understand that in today's fast-paced and interconnected world, staying ahead of the competition requires a strategic blend of creativity, expertise, and reliable IT infrastructure. That's why we offer a comprehensive range of services tailored to meet the unique needs of our clients.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='about_inner_div3'>
                    <div className='about_h4_div2' data-aos="fade-right" data-aos-duration='1500' data-aos-delay='100'>
                        <h4>WE AS A</h4>
                        <b>TEAM</b>
                    </div>
                    <div className='solution_list_text_div6' data-aos="fade-right" data-aos-duration='1500' data-aos-delay='100'>
                        <h4>Advisors & partners with a</h4>
                        <b>variety of backgrounds and skills</b>
                    </div>
                    <div className='about_card_main_div'>
                        {
                            team_obj?.map((v, i) => {
                                return (
                                    <>
                                        <div className='about_card_inner_div'>
                                            <div className='about_card_img_div'>
                                                <img src={v?.img} alt="" />
                                            </div>
                                            <div className='about_card_text'>
                                                <h2>{v?.name}</h2>
                                                <p>{v?.pos}</p>
                                            </div>
                                            <div className='about_card_icon_div'>
                                                <div className='about_card_icon_inner'>
                                                    <Link to={`mailto:${v?.email}`}>
                                                        <EmailOutlined className="about_div_icons" />
                                                    </Link>
                                                </div>
                                                <div className='about_card_icon_inner'>

                                                    <Link to={v?.linkedin}>
                                                        <GrLinkedinOption className='about_div_icons' />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='about_inner_div4'>
                    <div className='about_located_main_div'>
                        <div className='about_located_img'>
                            <img src={about_global_img} alt="" />
                        </div>
                        <div className='about_located_text_div'>
                            <div>
                                <div className='about_h4_div3'>
                                    <h4>LOCATED </h4>
                                    <b>GLOBALY</b>
                                </div>
                                <div className='about_located_inner_text' >
                                    <p>Our group of knowledgeable experts is passionate about creating cutting-edge solutions that boost productivity and promote corporate success. We have the knowledge to meet your unique needs, whether you need software development, cloud solutions, cybersecurity services, IT consulting, or managed IT services. To make sure that our solutions are reliable, scalable, and in line with your company objectives, we make use of the newest technology and best practises in the industry.</p>
                                </div>
                            </div>
                            <div className='about_located_icon_div'>
                                <div className='about_inner_icon_div'>
                                    <div><BsClock className='about_located_icon' /></div>
                                    <div className='about_inner_p_div'>
                                        <p>
                                            Covering most timezones
                                        </p>
                                    </div>
                                </div>
                                <div className='about_inner_icon_div'>
                                    <div><CiFlag1 className='about_located_icon' /></div>
                                    <div className='about_inner_p_div'>
                                        <p>
                                            Working with teams of diverse backgrounds
                                        </p>
                                    </div>
                                </div>
                                <div className='about_inner_icon_div'>
                                    <div><FaMapPin className='about_located_icon' /></div>
                                    <div className='about_inner_p_div'>
                                        <p>
                                            Located in Karachi, Pakistan
                                        </p>
                                        <img src={Pak_img} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='technologies_main_div2'>
                    <div className="technologies_heading_div" data-aos="fade-right" data-aos-duration='1500' data-aos-delay='100'>
                        <h3>OUR <b> PARTNERS</b></h3>
                        <p><span className='provide'>Benefit from our outstanding </span> corporate network</p>
                    </div>
                    <div className="images_div2">
                        {
                            partner_obj?.map((v, i) => {
                                return (
                                    <>
                                        <div className='img_div2' data-aos="fade-right" data-aos-duration='1500' data-aos-delay='100'>
                                            <img width={v?.width} src={v?.img} alt="" />
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div> */}
                <div className='schedule_main_div'>
                    <Schedule heading="SCHEDULE A CALL OR" bold="CONTACT US NOW" heading2="We'd love to" bold2=' hear about your project!' />
                </div>
            </div>
        </>
    )
}
export default About
