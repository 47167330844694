import React, { useState } from 'react'
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
const ScrollBar = () => {
    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 600) {
            setVisible(true)
        }
        else if (scrolled <= 600) {
            setVisible(false)
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    window.addEventListener('scroll', toggleVisible);
    return (
        <>
            <div className='top-to-btm'>
                <KeyboardArrowUpSharpIcon className='icon-position icon-style' onClick={scrollToTop}
                    style={{ display: visible ? 'inline' : 'none' }} />
            </div>
        </>
    )
}
export default ScrollBar
