import ecommerce_img from '../../assets/Image/ecommerce_img.png'

const WebDevelopment = () => {
    return (
        <>
            <div className="development_main_div">
                <div className='development_heading_div'>
                    <h1>Web Development</h1>
                </div>
                <div className="development_inner_div">
                    <div className="development_inner_div_2">
                        <h3>At The End Of The Day, It All Begins With A Stunning Website Design.</h3>
                        <p>
                            If you are looking for an outsourced web design agency that provides white label website design services at flexible pricing, using modish technology and tools, then you'll want to hire Code N Coder! Our website design company is known for our diligent and high-quality outsource web design work. We offer nothing less than the best — and that's why we are still unbeatable today!
                        </p>
                    </div>
                </div>
                <div className="development_ecommerce_div">
                    <div className="development_ecommerce_inner_div">
                        <h1>E-commerce website</h1>
                        <p>
                            E-commerce sites are no longer just online marketplaces where customers can browse through product pages and simply purchase what they want. It is now a way of life — fast, efficient, and simple. It's no longer just about having the right products in stock.
                            Everyone is searching for a business, and the businesses that are listed online receive more interest, and more hits. Considering your local shop is your website, the more appealing it is the more you can grab attention, you don’t have to pay for electricity, fuel, appliances or warehouses. You just need to pay for the attention a buyer might be looking at when browsing. That secret formula resides within us!
                        </p>
                    </div>
                    <div className="development_ecommerce_inner_div2">
                        <img src={ecommerce_img} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default WebDevelopment