import { FiMail } from "react-icons/fi";
import { Button } from "../../Component";
import contact_logo from "../../assets/Image/contact_logo.webp";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import { PopupButton } from "react-calendly";
import { BsArrowRepeat } from "react-icons/bs";

const Contact = () => {
  const form = useRef();
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messageResponse, setMessageResponse] = useState({});

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();

    if (!email || !name || !message) {
      setLoading(false);
      setMessageResponse({
        success: false,
        message: "Please Provide All Values.",
      });
      return true;
    }

    emailjs
      .sendForm(
        "service_ur7hwbr",
        "template_e4bkhc6",
        form.current,
        "gsT2fo66AqttMBe3H"
      )
      .then(
        (result) => {
          setLoading(false);
          setMessageResponse({
            success: true,
            message: "Send Email Successfully",
          });
          console.log("SEND EMAIL SUCCESSFULLY");
        },
        (e) => {
          setLoading(false);
          setMessageResponse({
            success: false,
            message: "Email Sending Error.",
          });
          console.log("EMAIL UN-SUCCESSFULL", e);
        }
      );
  };

  return (
    <>
      <div className="contact_main_Div">
        <div className="contact_icon_and_heading_div">
          <div className="solution_list_icon_div">
            <FiMail />
          </div>
          <div className="solution_list_text_div">
            <h4>HOW</h4>
            <b>CAN WE HELP?</b>
          </div>
        </div>
        <div className="contact_form_main">
          <div className="contact_heading_div">
            <h4>
              We’d love to hear about your project! Schedule a free discovery
              call to see how we can help or leave us a message.
            </h4>
          </div>
          <div className="form_main_div">
            <button style={{ cursor:'pointer',background: "#3176d2", borderRadius: "8px;" }}>
              <PopupButton
                className="PopupButton"
                rootElement={document.getElementById("root")}
                text="SCHEDULE A CALL"
                url="https://calendly.com/neptunestech/introductory-call"
              />
            </button>
            <form className="form_div" ref={form} onSubmit={sendEmail}>
              <div className="inputs_main_div">
                <div className="input_div">
                  <p>Name</p>
                  <input
                    onChange={(e) => {
                      setName(e.target.value);
                      setMessageResponse(null);
                    }}
                    type="text"
                    name="from_name"
                    placeholder="Vitalik Buterin"
                  />
                </div>
                <div className="input_div company_input_div">
                  <p>Company</p>
                  <input
                    name="company_name"
                    type="text"
                    placeholder="Binance"
                  />
                </div>
                <div className="input_div">
                  <p>
                    Email <span className="star">*</span>
                  </p>
                  <input
                    name="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setMessageResponse(null);
                    }}
                    type="email"
                    placeholder="www.neptunesTech.com"
                  />
                </div>
              </div>
              <div className="text_area_main">
                <div className="text_area_div">
                  <p>
                    What are you building? <span className="star"> *</span>
                  </p>
                  <textarea
                    className="text_area"
                    placeholder="I'm working on..."
                    rows="4"
                    name="message"
                    onChange={(e) => {
                      setMessage(e?.target?.value);
                      setMessageResponse(null);
                    }}
                  ></textarea>
                </div>
              </div>
              {messageResponse?.message && (
                <div style={{ textAlign: "center", marginTop: 10 }}>
                  {messageResponse?.success ? (
                    <b style={{ color: "green", textAlign: "center" }}>
                      {messageResponse?.message}
                    </b>
                  ) : (
                    <b style={{ color: "red", textAlign: "center" }}>
                      {messageResponse?.message}
                    </b>
                  )}
                </div>
              )}
              <div className="submit_btn_div">
                <button type="submit">
                  {loading ? (
                    <BsArrowRepeat className="loading-icon" />
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>
            </form>
            <p className="contact_form_div">
              For career opportunities please send your application to
              developers@neptunestech.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
