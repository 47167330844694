import React, { useState } from 'react';
import { FiEdit } from 'react-icons/fi'
import { BsFillDatabaseFill, BsFillPhoneFill } from 'react-icons/bs'
import { AiFillCloud, AiFillFile } from 'react-icons/ai'
import { FaTty } from 'react-icons/fa'
import Design from './Design';
import DataBase from './Database';
import Cloud from './Cloud';
import App_Tech from './App_Tech';
import WebTechnologies from './Web_Tech';
import Strategies from './Strategies';

const Tabs = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    return (
        <>
            <div className='tabs_main_div'>
                <div className='tabs_inner_div'>
                    <div className='dotted_line_div'>
                        <div className='tabs_circle'></div>
                        <div className="dotted_line"></div>
                        <div className='tabs_circle'></div>
                    </div>
                    <div className="tabs">
                        <div
                            className={`tab_inner ${activeTab === 1 ? 'active' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            <div className="tab_icon_div"><FiEdit className='tab_icon' /></div>
                            <div className="tab_text">Design</div>
                        </div>
                        <div
                            className={`tab_inner ${activeTab === 2 ? 'active' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            <div className="tab_icon_div"><BsFillDatabaseFill className='tab_icon' /></div>
                            <div className="tab_text">Database</div>
                        </div>
                        <div
                            className={`tab_inner ${activeTab === 3 ? 'active' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            <div className="tab_icon_div"><AiFillCloud className='tab_icon' /></div>
                            <div className="tab_text">Cloud</div>
                        </div>
                        <div
                            className={`tab_inner ${activeTab === 4 ? 'active' : ''}`}
                            onClick={() => handleTabClick(4)}
                        >
                            <div className="tab_icon_div"><BsFillPhoneFill className='tab_icon' /></div>
                            <div className="tab_text">App Technologies</div>
                        </div>
                        <div
                            className={`tab_inner ${activeTab === 5 ? 'active' : ''}`}
                            onClick={() => handleTabClick(5)}
                        >
                            <div className="tab_icon_div"><AiFillFile className='tab_icon' /></div>
                            <div className="tab_text">Web Technologies</div>
                        </div>
                        <div
                            className={`tab_inner ${activeTab === 6 ? 'active' : ''}`}
                            onClick={() => handleTabClick(6)}
                        >
                            <div className="tab_icon_div"><FaTty className='tab_icon' /></div>
                            <div className="tab_text">Strategies & Communication</div>
                        </div>
                    </div>
                </div>
                <div className='tabs2'>
                        <div
                            className={`tab_inner2 ${activeTab === 1 ? 'active2' : ''}`}
                            onClick={() => handleTabClick(1)}>
                            Design
                        </div>
                        <div
                            className={`tab_inner2 ${activeTab === 2 ? 'active2' : ''}`}
                            onClick={() => handleTabClick(2)}>
                            Database
                        </div>
                        <div
                            className={`tab_inner2 ${activeTab === 3 ? 'active2' : ''}`}
                            onClick={() => handleTabClick(3)}>
                            Cloud
                        </div>
                        <div
                            className={`tab_inner2 ${activeTab === 4 ? 'active2' : ''}`}
                            onClick={() => handleTabClick(4)}>
                            App Technologies
                        </div>
                        <div
                            className={`tab_inner2 ${activeTab === 5 ? 'active2' : ''}`}
                            onClick={() => handleTabClick(5)}>
                            Web Technologies
                        </div>
                        <div
                            className={`tab_inner2 ${activeTab === 6 ? 'active2' : ''}`}
                            onClick={() => handleTabClick(6)}>
                            Strategies & Communication
                        </div>
                </div>
            </div>
            <div className="content">
                {activeTab === 1 && <div className="content_text"><Design /></div>}
                {activeTab === 2 && <div className="content_text"><DataBase /></div>}
                {activeTab === 3 && <div className="content_text"><Cloud /></div>}
                {activeTab === 4 && <div className="content_text"><App_Tech /></div>}
                {activeTab === 5 && <div className="content_text"><WebTechnologies /></div>}
                {activeTab === 6 && <div className="content_text"><Strategies /></div>}
            </div>
        </>
    );
};

export default Tabs;
