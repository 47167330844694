import React from "react";
import Slider from "react-slick";
import imageone from '../../assets/Image/sliderImageone.webp'
import imagetwo from '../../assets/Image/sliderImagetwo.webp'
import imagethree from '../../assets/Image/sliderImagethree.webp'
import imagefour from '../../assets/Image/sliderImagefour.webp'

function SimpleSlider() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div>
            <Slider {...settings}>
                <div>
                    <p className="slider_text"><i>"As a developer myself, they helped me with a difficult project to create a react.js and node.js web application. They responded to my enquiry message within minutes and delivered the project to me before the deadline was even due. On top of this, they took the time to have a zoom meeting where the developer gave a detailed walkthrough of how he made the code as well as showing me how I can deploy the app myself. Overall, very quick, easy, and above my expectations. I will definitely be coming back here if I have any other projects."</i></p>
                    <div className="slider_img_and_name_div_main">
                        <div className="slider_img_and_name_div">
                            <img className="slider_img" src={imageone} alt="" />
                            <div>
                                <p className="slider_name">lewisistrefi1</p>
                                <p className="slider_name">United Kingdom</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="slider_text">
                        <i>"The project scope has been well delivered according to our requirements. He has been able to understand the logic effectively and completed our webapp according to our UI design. The timeline is tight but he is able to deliver the project accordingly. The slight delay is acceptable."</i></p>
                    <div className="slider_img_and_name_div_main">
                        <div className="slider_img_and_name_div">
                            <img className="slider_img" src={imagetwo} alt="" />
                            <div>
                                <p className="slider_name">altosssss</p>
                                <p className="slider_name">HongKong</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="slider_text">
                        <i>"Excellent work and great communication. Delivery was fast and appreciated the support that was provided. I do not often come across sellers of this caliber. Highly recommended and will definetely order again. Thanks again you for the hassle free experience!"</i></p>
                    <div className="slider_img_and_name_div_main">
                        <div className="slider_img_and_name_div">
                            <img className="slider_img" src={imagethree} alt="" />
                            <div>
                            <p className="slider_name">gvancleem</p>
                            <p className="slider_name">Canada</p></div>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="slider_text"><i>"Mansoor and his team are very competent and have excellent communication. you're in safe hands working with him. His experience really shines through."</i></p>
                    <div className="slider_img_and_name_div_main">
                        <div className="slider_img_and_name_div">
                            <img className="slider_img" src={imagefour} alt="" />
                            <div>
                                <p className="slider_name">ashrafrahman166</p>
                                <p className="slider_name">United Kingdom</p></div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
}

export default SimpleSlider;
