import React from 'react'
import Navbar from '../Navbar/Navbar'
import WorkingGirlImg from '../../assets/Image/working_girl.jpg'
import { Link } from 'react-router-dom'
const Works = () => {
    return (
        <div>
            <Navbar />
            <div className='WeWorks_main_Section'>
                <div className="worksMain_heading_div">
                    <h1 className="worksMain_heading" >How We Work</h1>
                </div>
                
                 <div className='work_main_Section'>
                    <div className="working_Girl_div">
                        <div className="meetImg"></div>
                    </div>

                    <div className="How_it_works_text_div">
                        <div>
                            <h1 className='How_it_works'>How it works</h1>
                            <div className='blue_bottom_line'></div>
                        </div>
                        <div className="Works_paragraph_section">
                            <p className="Works_paragraph">
                                We are a team of seasoned professionals with more than 10 years of rich experience in web application development, product engineering and web design. We provide our services to a wide range of clients globally across various verticals including financial, healthcare and consumer goods. Our ability to work with multiple technologies gives us an edge in adapting ourselves to the changes around us.</p>
                            <p className="Works_paragraph">
                                Our expert consultants help us deliver high-quality products that are customized as per the requirements of our clients.</p>
                            <p className="Works_paragraph">
                                Our process is designed to deliver value, on time, everytime. From start to finish, we make sure your project is executed with the highest quality standards. And because it’s done with the customer’s input from the beginning, the end result is perfect every time - which means you get exactly what you want, exactly when you want it.</p>
                              
                            <Link to='/Contact'>
                                <button className='ExpertNow_btn'>Take To Our Expert Now</button>

                              </Link>  


                        </div>

                    </div>




                </div>     
              

            </div>
        </div>



    )
}

export default Works
