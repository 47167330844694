import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { IoMenuSharp, IoClose } from 'react-icons/io5';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { BiChevronRight } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { MOBILE_APP, SOFTWARE_DEVELOPMENT, USER_EXPERIENCE, WEB_DEVELOPMENT, WEWORK } from '../../Config/path';

export default function NavDrawer() {
    const [state, setState] = useState({ right: false });
    const [serviceBtn, setServiceBtn] = useState(false)
    const [aboutBtn, setAboutBtn] = useState(false)
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const toggleClose = () => {
        setState(false)
        setServiceBtn(false)
        setAboutBtn(false)
    }
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '315px' }}
            className='drawer_div'
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {serviceBtn === false && aboutBtn === false ?
                    <div>
                        <div className='close_icon_div'>
                            <IoClose className='close_icon' onClick={toggleClose} />
                        </div>
                        <ul className='mobile_nav_ul'>
                            <li>
                                <Link onClick={toggleClose}
                                    className='mobile_link' to='/'>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link onClick={toggleClose}
                                    className='mobile_link' to='/technologies'>
                                    Technologies
                                </Link>
                            </li>
                            <li>
                                <Link onClick={toggleClose}
                                    className='mobile_link' to='/project'>
                                    Project
                                </Link>
                            </li>
                            <li>
                                <Link onClick={toggleClose}
                                    className='mobile_link' to='/service'>
                                    Services
                                </Link>
                                <button onClick={() => setServiceBtn(true)}><BiChevronRight className='right_arror_icon' /></button>
                            </li>
                            <li>
                                <Link onClick={toggleClose}
                                    className='mobile_link' to='/about'>
                                    About
                                </Link>
                                <button onClick={() => setAboutBtn(true)}><BiChevronRight className='right_arror_icon' /></button>
                            </li>
                            <li>
                                <Link onClick={toggleClose}
                                    className='mobile_link' to='/contact'>
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                    :
                    null
                }
                {serviceBtn === true ?
                    <div>
                        <div className='close_arrow_icon_div'>
                            <AiOutlineArrowLeft className='close_icon' onClick={() => setServiceBtn(false)} />
                            <IoClose className='close_icon' onClick={toggleClose} />
                        </div>
                        <ul className='mobile_nav_ul'>
                            <li>
                                <Link onClick={toggleClose}
                                    className='mobile_link' to={SOFTWARE_DEVELOPMENT}>
                                    Software Development
                                </Link>
                            </li>
                            <li>
                                <Link onClick={toggleClose}
                                    className='mobile_link' to={WEB_DEVELOPMENT}>
                                    Website Development
                                </Link>
                            </li>
                            <li>
                                <Link onClick={toggleClose}
                                    className='mobile_link' to={MOBILE_APP}>
                                    Mobile Applictation
                                </Link>
                            </li>
                            <li>
                                <Link onClick={toggleClose}
                                    className='mobile_link' to={USER_EXPERIENCE}>
                                    UI/UX Designing
                                </Link>
                            </li>
                        </ul>
                    </div>
                    :
                    null
                }
                {aboutBtn === true ?
                    <div>
                        <div className='close_arrow_icon_div'>
                            <AiOutlineArrowLeft className='close_icon' onClick={() => setAboutBtn(false)} />
                            <IoClose className='close_icon' onClick={toggleClose} />
                        </div>
                        <ul className='mobile_nav_ul'>
                            <li>
                                <Link onClick={toggleClose}
                                    className='mobile_link' to={WEWORK}>
                                    Work With Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                    :
                    null
                }
            </List>
        </Box>
    );

    return (
        <div>
            <button className='menu_btn' onClick={toggleDrawer('right', true)}>
                <IoMenuSharp className='menu_icon' />
            </button>
            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >
                {list('right')}
            </Drawer>
        </div>
    );
}
